import React from "react";
import styled from "styled-components";
import { breakpoints, colors, maxWidth } from "../../styles/variables";
import { getColor } from "../helpers/getColor";

const PhoneElem = styled.a`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  background: ${(props) => props.color};
  position: fixed;
  top: 54px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  text-decoration: none;
  padding: 0 15px;
  z-index: 7;
  .no-mobile {
    display: none;
  }

  > div {
    display: flex;
    flex-direction: row;
    width: max-content;
    gap: 12px;
    font: 17px / 45px "FS Medium";

    > div {
      color: white;
    }

    .icon {
      width: 22px;
      height: 22px;
      margin-top: 10px;
      background: url("/assets/gestaltungselemente/icon-phone.svg");
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 24px;
    box-shadow: none;
    width: max-content;
    background: white;
    padding: 0;
    position: fixed;
    right: 40px;
    z-index: 12;
    top: 16px;

    .no-mobile {
      display: inherit;
    }

    > div {
      font: 21px / 26px "FS Medium";
      padding: 8px 20px;
      border: 1px solid ${colors.text};
      > div {
        color: ${colors.text};
      }
      .icon {
        width: 27px;
        height: 27px;
        background: url("/assets/gestaltungselemente/icon-phone-black.svg");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: 0;
      }
    }
  }

  @media screen AND (min-width: ${maxWidth}px) {
    right: calc((100vw - ${maxWidth}px) / 2 + 40px);
  }
`;

interface PhoneProps {
  phone: string;
  siteId: number;
}

const Phone: React.FC<PhoneProps> = ({ phone, siteId }) => (
  <PhoneElem href={"tel:" + phone} color={getColor(siteId)}>
    <div>
      <div className="no-mobile">Kontakt</div>
      <div className="icon" />
      <div>{phone}</div>
    </div>
  </PhoneElem>
);

export default Phone;
