/* eslint-disable max-len */

export const colors = {
  blue: "#1678A6",
  red: "#C2062B",
  orange: "#E49B00",
  green: "#769A2B",
  text: "#3C3C3C",
  textBlack: "#000000",
};

export const breakpoints = {
  tablet: 700,
  desktop: 1200,
};

export const maxWidth = 1920;

export const heights = {
  header: 60,
};

export const innerPadding = {
  footer: {
    // mobile: "16px 40px 24px",
    desktop: "16px 40px 24px",
  },
};

export const imgix = {
  fullImage: {
    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 768
          : window.innerWidth < 1280
          ? 1280
          : window.innerWidth >= 1280
          ? 1920
          : 1920
        : 1920,
  },
  halfImage: {
    fit: "crop",
    crop: "entropy",

    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 384
          : window.innerWidth < 1280
          ? 640
          : window.innerWidth >= 1280
          ? 960
          : 960
        : 960,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 232
          : window.innerWidth < 1280
          ? 384
          : window.innerWidth >= 1280
          ? 576
          : 576
        : 576,
  },
  thirdImage: {
    fit: "crop",
    crop: "entropy",

    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 256
          : window.innerWidth < 1280
          ? 427
          : window.innerWidth >= 1280
          ? 640
          : 640
        : 640,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 256
          : window.innerWidth < 1280
          ? 427
          : window.innerWidth >= 1280
          ? 640
          : 640
        : 640,
  },
  galleryImage: {
    fit: "crop",
    crop: "faces",

    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 768
          : window.innerWidth < 1280
          ? 1280
          : window.innerWidth >= 1280
          ? 1280
          : 1280
        : 1280,
    h:
      typeof window !== "undefined" //hier proportionen wie gallery-grid umrechnen
        ? window.innerWidth < 768
          ? 384
          : window.innerWidth < 1200
          ? 600
          : window.innerWidth >= 1200
          ? 583
          : 583
        : 583,
  },
  // optimize
  galleryGrid: {
    fit: "crop",
    crop: "faces",

    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 256
          : window.innerWidth < 1280
          ? 427
          : window.innerWidth >= 1280
          ? 640
          : 640
        : 640,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 128
          : window.innerWidth < 1280
          ? 213
          : window.innerWidth >= 1280
          ? 320
          : 320
        : 320,
  },
  mitarbeiterImage: {
    fit: "crop",
    crop: "center",

    w: 600,
    h: 600,
  },
};
