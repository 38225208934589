import * as React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const StyledLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 99px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: 133px;
  }
`;

const LayoutMain: React.FC = ({ children }) => (
  <StyledLayoutMain>{children}</StyledLayoutMain>
);

export default LayoutMain;
