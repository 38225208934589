import React from "react";
import styled from "styled-components";
import BackgroundWrapper from "../BackgroundWrapper";
import LowerFooter from "./LowerFooter";
import UpperFooter from "./UpperFooter";

const FooterElem = styled.footer`
  max-width: 1920px;
  margin: 0 auto;
`;

// 1: Verein
// 2: Betreutes Wohnen Heiligkreuz
// 3: Bruggen
// 4: Heiligkreuz

interface FooterProps {
  data: {
    siteId: number;
    title: string;
    metaBeschreibung: string;
    adresseLine1: string;
    plzUndOrt: string;
    telefon: string;
    email: string;
    latitude: number;
    longitude: number;
  };
  siteId: number;
  lowerFooter: [
    {
      siteId: number;
      title: string;
    }
  ];
}

const Footer: React.FC<FooterProps> = ({ data, siteId, lowerFooter }) => (
  <BackgroundWrapper>
    <FooterElem>
      <UpperFooter data={data} siteId={siteId} />
      <LowerFooter siteId={siteId} lowerFooter={lowerFooter} />
    </FooterElem>
  </BackgroundWrapper>
);

export default Footer;
