// 1: Verein
// 2: Betreutes Wohnen Heiligkreuz
// 3: Bruggen
// 4: Heiligkreuz
export const getUrl = (siteId) => {
  if (siteId === 1) {
    return "https://www.evang-pflegeheime.ch/";
    //   return "https://www.evangpfl.goeasttest.cloud";
  }
  if (siteId === 2) {
    return "https://www.betreutes-wohnen-heiligkreuz.ch/";
    //   return "https://www.betreuth.goeasttest.cloud";
  }
  if (siteId === 3) {
    return "https://www.pflegeheim-bruggen.ch/";
    //   return "https://www.pflegehb.goeasttest.cloud";
  }
  if (siteId === 4) {
    return "https://www.pflegeheim-heiligkreuz.ch/";
    //   return "https://www.pflegehh.goeasttest.cloud";
  }
};
