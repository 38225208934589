import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const BackgroundDiv = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.73);
  z-index: 6;
  /* transition: top 1000ms; */

  &.open {
    top: 0;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;

interface MobileNavBackgroundProps {
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navIsOpen: boolean;
}

const MobileNavBackground: React.FC<MobileNavBackgroundProps> = ({
  navIsOpen,
  setNavIsOpen,
}) => (
  <BackgroundDiv
    className={navIsOpen ? "open" : ""}
    onClick={() => setNavIsOpen(!navIsOpen)}
  />
);

export default MobileNavBackground;
