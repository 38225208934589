import React from "react";
import styled from "styled-components";
import { breakpoints, innerPadding } from "../../styles/variables";

const PartElem = styled.a`
  display: block;
  background: ${(props) => props.heimColor};
  padding: 13.5px 15px 17.5px;
  text-decoration: none;

  a {
    text-decoration: none;
  }

  * {
    color: white;
  }

  h2 {
    font: 21px / 25px "FS Medium";
    margin-top: 10px;
  }

  &:not(.active) {
    height: 65px;
    display: grid;
    grid-template-columns: 38px 1fr;
    grid-gap: 10px;
  }

  .icon {
    width: 38px;
    height: 34.78px;
    background: url("/assets/gestaltungselemente/icon-white.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  h4 {
    margin-top: 4px;
    font: 15px / 17px "FS Medium";
  }

  address {
    margin: 15px 0 26px;
    font-style: normal;
    font: 13px / 20px "FS Regular";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: ${innerPadding.footer.desktop};

    &:not(.active) {
      height: unset;
      display: block;

      h4 {
        max-width: 300px;
      }

      &:hover {
        background: white;
        cursor: pointer;

        h4 {
          color: ${(props) => props.heimColor};
        }

        .icon {
          background: ${(props) =>
            `url('/assets/gestaltungselemente/${props.icon}')`};

          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .icon {
      width: 38px;
      height: 34.78px;
      margin-bottom: 12px;
    }

    h4,
    h2 {
      font: 24px / 26px "FS Medium";
    }

    h2 {
      margin-top: 0;
    }

    address {
      margin: 14px 0 26px;
      font: 14px / 21px "FS Regular";
    }
  }
`;

const PartElemDiv = styled.div`
  display: block;
  background: ${(props) => props.heimColor};
  padding: 13.5px 15px 17.5px;
  text-decoration: none;

  a {
    text-decoration: none;
  }

  * {
    color: white;
  }

  h2 {
    font: 21px / 25px "FS Medium";
    margin-top: 10px;
  }

  &:not(.active) {
    min-height: 65px;
    display: grid;
    grid-template-columns: 38px 1fr;
    grid-gap: 10px;
  }

  .icon {
    width: 38px;
    height: 34.78px;
    background: url("/assets/gestaltungselemente/icon-white.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  h4 {
    margin-top: 4px;
    font: 15px / 17px "FS Medium";
  }

  address {
    margin: 15px 0 26px;
    font-style: normal;
    font: 13px / 20px "FS Regular";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: ${innerPadding.footer.desktop};

    &:not(.active) {
      height: unset;
      display: block;

      h4 {
        max-width: 300px;
      }

      &:hover {
        background: white;
        cursor: pointer;

        h4 {
          color: ${(props) => props.heimColor};
        }

        .icon {
          background: ${(props) =>
            `url('/assets/gestaltungselemente/${props.icon}')`};

          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .icon {
      width: 38px;
      height: 34.78px;
      margin-bottom: 12px;
    }

    h4,
    h2 {
      font: 24px / 26px "FS Medium";
    }

    h2 {
      margin-top: 0;
    }

    address {
      margin: 14px 0 26px;
      font: 14px / 21px "FS Regular";
    }
  }
`;

interface FooterPartProps {
  color: string;
  icon: string;
  active?: boolean;
  link?: string;
}

const FooterPart: React.FC<FooterPartProps> = ({
  children,
  color,
  icon,
  active,
  link,
}) => (
  <>
    {link ? (
      <PartElem
        heimColor={color}
        icon={icon}
        className={active ? "active" : ""}
        href={link ? link : ""}
        target={active ? "_blank" : "_self"}
      >
        <div className="icon" alt="icon" />
        <div>{children}</div>
      </PartElem>
    ) : (
      <PartElemDiv
        heimColor={color}
        icon={icon}
        className={active ? "active" : ""}
      >
        <div className="icon" alt="icon" />
        <div>{children}</div>
      </PartElemDiv>
    )}
  </>
);

export default FooterPart;
