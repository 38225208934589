import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { getColor } from "../helpers/getColor";
import MapBox from "../Map/MapBox";
import Copyright from "./Copyright";
import FooterPart from "./FooterPart";
import { getFooterTitle } from "../helpers/getFooterTitle";

const UpperFooterElem = styled.div`
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

interface UpperFooterProps {
  siteId: number;
  data: {
    siteId: number;
    title: string;
    metaBeschreibung: string;
    adresseLine1: string;
    plzUndOrt: string;
    telefon: string;
    email: string;
    latitude: number;
    longitude: number;
  };
}

const UpperFooter: React.FC<UpperFooterProps> = ({ siteId, data }) => (
  <UpperFooterElem>
    <FooterPart color={getColor(siteId)} active>
      <h2
        dangerouslySetInnerHTML={{
          __html: getFooterTitle(data.siteId).desktop,
        }}
      ></h2>
      <address>
        {data.title}
        <br />
        {data.adresseLine1}, {data.plzUndOrt} <br />
        <a href={"tel:" + data.telefon}>{data.telefon}</a>
        <br />
        <a href={"mailto:" + data.email}>{data.email}</a>
      </address>
      <Copyright />
    </FooterPart>
    {data.latitude ? (
      <MapBox
        lat={data.latitude}
        long={data.longitude}
        siteId={siteId}
        kartenLink={data.kartenLink}
      />
    ) : (
      <MapBox lat={10} long={10} />
    )}
  </UpperFooterElem>
);

export default UpperFooter;
