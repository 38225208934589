import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { getColor } from "../helpers/getColor";
import { getFaviconFolder } from "../helpers/getFaviconFolder";

const FliesstextElem = styled.div`
  &.centered {
    text-align: center;
    width: 100%;
    margin: 30px auto 36px;

    ul,
    ol {
      list-style-type: none;
      margin-left: 0;
    }
  }

  ul,
  ol {
    margin-bottom: 16px;
    li {
      font: 16px / 21px "FS Regular";
      margin-bottom: 10px;
    }
  }

  p {
    font: 16px / 21px "FS Regular";
    margin-bottom: 16px;
    b,
    strong {
      font-family: "FS Medium";
    }
  }

  h4 {
    font: 16px / 21px "FS Medium";
    margin-bottom: 10px;
  }

  a {
    color: ${(props) => props.linkColor};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  a[href$=".jpg"],
  a[href$=".png"],
  a[href$=".pdf"],
  a[href$=".doc"],
  a[href$=".docx"],
  a[href$=".zip"] {
    padding-left: 24px;
    background: url("/assets/gestaltungselemente/icon-download-text-black.svg");
    background-position: left center;
    background-size: 16px;
    background-repeat: no-repeat;
    color: ${colors.text};
    text-decoration: none;
    overflow: visible;

    &:hover {
      background: url(${(props) => props.downloadIcon});
      background-position: left center;
      background-size: 16px;
      background-repeat: no-repeat;
      color: ${(props) => props.linkColor};
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    &.centered {
      margin: 40px auto 90px;
    }
    p {
      font: 21px / 30px "FS Regular";
      margin-bottom: 37px;
    }

    h4 {
      font: 21px / 30px "FS Medium";
      margin-bottom: 17px;
    }

    ul,
    ol {
      margin-bottom: 48px;

      li {
        font: 21px / 30px "FS Regular";
        margin-bottom: 15px;
      }
    }

    a[href$=".jpg"],
    a[href$=".png"],
    a[href$=".pdf"],
    a[href$=".doc"],
    a[href$=".docx"],
    a[href$=".zip"] {
      padding-left: 30px;
      background: url("/assets/gestaltungselemente/icon-download-text-black.svg");
      background-position: left 10px;
      background-size: 20px;
      background-repeat: no-repeat;
      color: ${colors.text};
      text-decoration: none;

      &:hover {
        background: url(${(props) => props.downloadIcon});
        background-position: left 10px;
        background-size: 20px;
        background-repeat: no-repeat;
        color: ${(props) => props.linkColor};
      }
    }
  }
`;

interface FliesstextProps {
  centered?: boolean;
  text: string;
  color: string;
}

const Fliesstext: React.FC<FliesstextProps> = ({ centered, text, siteId }) => (
  <FliesstextElem
    className={centered ? "centered" : ""}
    dangerouslySetInnerHTML={{ __html: text }}
    linkColor={getColor(siteId)}
    downloadIcon={`/assets/gestaltungselemente/icon-download-text-${getFaviconFolder(
      siteId
    )}.svg`}
  ></FliesstextElem>
);

export default Fliesstext;
