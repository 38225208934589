export const getMapStyle = (siteId) => {
  if (siteId === 1) {
    return "mapbox://styles/goeast/cl63xwim2000614rzqfmis5k2";
  }
  if (siteId === 2) {
    return "mapbox://styles/goeast/cl63xv4ig001j14l7wnallhjg";
  }
  if (siteId === 3) {
    return "mapbox://styles/goeast/cl63xtjyk000e14muibcmdnse";
  }
  if (siteId === 4) {
    return "mapbox://styles/goeast/cl63xshfe001l15nv7vjzydy5";
  }
};
