import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import { getIcon } from "../helpers/getIcon";

const LogoElem = styled(Link)`
  text-decoration: none;
  .mobileLogo {
    display: grid;
    grid-template-columns: 37.14px 1fr;
    grid-gap: 10px;

    img {
      height: 34px;
    }

    h2 {
      margin-top: 1px;
      font: 16px / 18px "FS Medium";
      font-variant-ligatures: no-common-ligatures;
    }
  }
  .desktopLogo {
    display: none;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    .mobileLogo {
      display: none;
    }
    .desktopLogo {
      display: block;
      height: 100px;
      margin-top: -6px;
    }
  }
`;

interface LogoProps {
  siteId: number;
}

const Logo: React.FC<LogoProps> = ({ siteId }) => (
  <LogoElem to="/">
    <div className="mobileLogo">
      <img src={"/assets/gestaltungselemente/" + getIcon(siteId)} />
      {siteId === 1 ? (
        <h2>
          Verein Evangelische Pflegeheime
          <br />
          St. Gallen
        </h2>
      ) : siteId === 2 ? (
        <h2>
          Betreutes Wohnen
          <br />
          Heiligkreuz
        </h2>
      ) : siteId === 3 ? (
        <h2>
          Pflegeheim
          <br />
          Bruggen
        </h2>
      ) : siteId === 4 ? (
        <h2>
          Pflegeheim <br />
          Heiligkreuz
        </h2>
      ) : (
        ""
      )}
    </div>
    <img
      className="desktopLogo"
      src={`/assets/logos/${
        siteId === 1
          ? `verein-pflegeheime.svg`
          : siteId === 2
          ? `betreutes-wohnen-heiligkreuz.svg`
          : siteId === 3
          ? `pflegeheim-bruggen.svg`
          : siteId === 4
          ? `pflegeheim-heiligkreuz.svg`
          : ``
      }`}
      alt="Logo Pflegeheime"
    />
  </LogoElem>
);

export default Logo;
