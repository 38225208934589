export const getFaviconFolder = (siteId) => {
  if (siteId === 1) {
    return "red";
  }
  if (siteId === 2) {
    return "green";
  }
  if (siteId === 3) {
    return "orange";
  }
  if (siteId === 4) {
    return "blue";
  }
};
