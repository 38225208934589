import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const BGWrapper = styled.div`
  > div {
    width: 100vw;
  }

  &.standardMargin {
    margin-bottom: 40px;
    @media screen AND (min-width: ${breakpoints.desktop}px) {
      margin-bottom: 90px;
    }
  }
`;

const BackgroundWrapper = ({ children, standardMargin }) => (
  <BGWrapper className={standardMargin ? "standardMargin" : ""}>
    <div>{children}</div>
  </BGWrapper>
);

export default BackgroundWrapper;
