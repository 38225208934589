import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { getUrl } from "../helpers/getUrl";

const CopyrightElem = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  column-gap: 7px;

  div {
    img {
      margin-top: 10px;
      width: 18px;
      height: 18px;
    }

    p {
      font: 11px / 18px "FS Regular";
    }
  }
`;

const Copyright = () => (
  <CopyrightElem>
    <div>
      <img
        src="/assets/gestaltungselemente/icon-copyright.svg"
        alt="copyright icon"
      />
    </div>
    <div>
      <p>
        Verein Evangelische Pflegeheime St.Gallen <br />
        <a href={getUrl(1) + "datenschutzerklaerung"}>
          Datenschutzerklärung
        </a> | <Link to="/impressum">Impressum</Link>
      </p>
    </div>
  </CopyrightElem>
);

export default Copyright;
