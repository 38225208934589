import React, { useState } from "react";
import styled from "styled-components";
import ReactMapGL, { Marker } from "react-map-gl";
import { getColor } from "../helpers/getColor";
import { getMapMarker } from "../helpers/getMapMarker";
import { getMapStyle } from "../helpers/getMapStyle";
// import MapMarker from "./MapMarker";

const MarkerElem = styled.div`
  position: absolute;
  left: 0;

  img {
    display: block;
    width: 30px;
    height: 45.21px;
    margin-top: -45.21px;
    margin-left: -15px;
  }
`;

const MapBox: React.FC = ({ lat, long, siteId, kartenLink }) => {
  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: long,
    zoom: 16,
  });

  return (
    <a
      href={kartenLink}
      style={{
        display: "block",
        cursor: "pointer",
      }}
      target="_blank"
    >
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={setViewport}
        mapStyle={getMapStyle(siteId)}
        mapboxApiAccessToken="pk.eyJ1IjoiZ29lYXN0IiwiYSI6ImNqcWY5OXJmajIxMG00MnQ3ZXpsMGFwcDIifQ.5hMtuM7NCx7STL38ftqtMg"
      >
        <Marker longitude={long} latitude={lat} color={getColor(siteId)}>
          <MarkerElem>
            <img src={"/assets/gestaltungselemente/" + getMapMarker(siteId)} />
          </MarkerElem>
        </Marker>
      </ReactMapGL>
    </a>
  );
};

export default MapBox;
