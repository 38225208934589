export const getIcon = (siteId) => {
  if (siteId === 1) {
    return "icon-red.svg";
  }
  if (siteId === 2) {
    return "icon-green.svg";
  }
  if (siteId === 3) {
    return "icon-orange.svg";
  }
  if (siteId === 4) {
    return "icon-blue.svg";
  }
};
