import React from "react";
import styled from "styled-components";
import { breakpoints, maxWidth } from "../../styles/variables";
import Logo from "./Logo";
import MobileNavBackground from "./MobileNavBackground";
import Navigation from "./Navigation";
import Phone from "./Phone";
import Toggle from "./Toggle";

const OuterHeader = styled.div`
  width: 100%;
  height: 54px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: white;
  overflow: hidden;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: 133px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

    .right {
      display: flex;
      flex-direction: column;
    }
  }
`;

const HeaderElem = styled.header`
  max-width: ${maxWidth}px;
  padding: 9px 15px 11px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 11;
  margin: 0 auto;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 40px 13px;
  }
`;

interface HeaderProps {
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navIsOpen: boolean;
  content: [
    {
      title: string;
      siteId: number;
      uri: string;
    }
  ];
  phone: string;
  siteId: number;
}

const Header: React.FC<HeaderProps> = ({
  setNavIsOpen,
  navIsOpen,
  content,
  phone,
  siteId,
}) => (
  <>
    <OuterHeader>
      <HeaderElem>
        <Logo siteId={siteId} />
        <Toggle setNavIsOpen={setNavIsOpen} navIsOpen={navIsOpen} />
      </HeaderElem>
    </OuterHeader>
    <Phone phone={phone} siteId={siteId} />
    <MobileNavBackground navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
    <Navigation
      navIsOpen={navIsOpen}
      setNavIsOpen={setNavIsOpen}
      content={content}
      siteId={siteId}
    />
  </>
);

export default Header;
