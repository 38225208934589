export const getFooterTitle = (siteId) => {
  if (siteId === 1) {
    return {
      mobile: "Verein Evangelische Pflegeheime <br />St.Gallen",
      desktop: "Verein Evangelische <br />Pflegeheime St.Gallen",
    };
  } else if (siteId === 2) {
    return {
      mobile: "Betreutes Wohnen <br />Heiligkreuz",
      desktop: "Betreutes Wohnen <br />Heiligkreuz",
    };
  } else if (siteId === 3) {
    return {
      mobile: "Pflegeheim <br />Bruggen",
      desktop: "Pflegeheim <br />Bruggen",
    };
  } else if (siteId === 4) {
    return {
      mobile: "Pflegeheim <br />Heiligkreuz",
      desktop: "Pflegeheim <br />Heiligkreuz",
    };
  }
};
