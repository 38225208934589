import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { getColor } from "../helpers/getColor";
import { getFooterTitle } from "../helpers/getFooterTitle";
import { getIcon } from "../helpers/getIcon";
import FooterPart from "./FooterPart";
import { getUrl } from "../helpers/getUrl";

const LowerFooterElem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 65px;

  .desktop {
    display: none;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    .desktop {
      display: inherit;
    }

    .mobile {
      display: none;
    }
  }
`;

const LowerFooter = ({ siteId, lowerFooter }) => {
  const filteredItems = lowerFooter.filter((item) => item.siteId != siteId);

  return (
    <LowerFooterElem>
      {filteredItems.map((item, index) => (
        <FooterPart
          color={getColor(item.siteId)}
          icon={getIcon(item.siteId)}
          key={index}
          link={getUrl(item.siteId)}
        >
          <h4
            className="mobile"
            dangerouslySetInnerHTML={{
              __html: getFooterTitle(item.siteId)?.mobile,
            }}
          ></h4>
          <h4
            className="desktop"
            dangerouslySetInnerHTML={{
              __html: getFooterTitle(item.siteId)?.desktop,
            }}
          ></h4>
        </FooterPart>
      ))}
    </LowerFooterElem>
  );
};

export default LowerFooter;
