import * as React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.open {
    height: 100vh;
    overflow: hidden;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    &.open {
      height: auto;
      overflow: scroll;
    }
  }
`;

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, navIsOpen }) => (
  <>
    <StyledLayoutRoot className={navIsOpen ? "open" : ""}>
      {children}
    </StyledLayoutRoot>
  </>
);

export default LayoutRoot;
