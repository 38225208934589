import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const WrapperElem = styled.div`
  width: 100%;
  padding: 0 36px;
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    max-width: 980px;
    margin: 0 auto;

    &.wider {
      max-width: 1200px;
    }
  }
`;

const Wrapper = ({ children, wider }) => (
  <WrapperElem className={wider ? "wider" : ""}>{children}</WrapperElem>
);

export default Wrapper;
