import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors, maxWidth } from "../../styles/variables";
import { getColor } from "../helpers/getColor";

const NavigationElem = styled.nav`
  /* display: none; */
  width: 100vw;
  height: max-content;
  max-height: 100vh;
  overflow: scroll;
  z-index: 9;
  position: fixed;
  left: 0;
  background: white;
  top: -100vh;
  transition: top 600ms;

  &.open {
    top: 54px;
  }

  ul {
    list-style-type: none;
    margin-left: 61px;
    padding: 20px 0 60px;

    li {
      font: 22px / 66px "FS Regular";
      a {
        text-decoration: none;
        &:hover,
        &.active {
          color: ${(props) => props.hoverColor};
        }
        &.active {
          font-family: "FS Medium";
        }
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: calc(70%);
    max-width: 1500px;
    display: block;
    position: fixed;
    right: 40px;
    padding: 0;
    z-index: 12;
    top: 67px;
    right: 40px;
    left: unset;
    background: rgba(255, 255, 255, 0);
    transition: none;
    overflow: hidden;

    &.shorter {
      width: 50%;
    }

    &.open {
      top: 67px;
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      margin-left: 0;
      padding: 0;
      padding-left: 60px;

      li a {
        font: 22px / 31px "FS Regular";
      }
    }
  }

  @media screen AND (min-width: 1366px) {
    width: calc(70%);
    ul {
      gap: 40px;
    }
  }

  @media screen AND (min-width: 1440px) {
    width: calc(75%);
    ul {
      gap: 40px;
    }
  }

  @media screen AND (min-width: ${maxWidth}px) {
    right: calc((100vw - ${maxWidth}px) / 2 + 40px);
  }
`;

interface NavigationProps {
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navIsOpen: boolean;
  content: [
    {
      title: string;
      siteId: number;
      uri: string;
    }
  ];
  siteId: number;
}

const Navigation: React.FC<NavigationProps> = ({
  navIsOpen,
  setNavIsOpen,
  content,
  siteId,
}) => (
  <NavigationElem
    className={
      navIsOpen && siteId === 1
        ? "open shorter"
        : navIsOpen
        ? "open"
        : siteId === 1
        ? "shorter"
        : ""
    }
    hoverColor={getColor(siteId)}
  >
    <ul>
      {content.map((item, index) =>
        item.uri != "__home__" ? (
          <li key={index}>
            <Link
              activeClassName="active"
              to={"/" + item.uri}
              onClick={() => setNavIsOpen(!navIsOpen)}
            >
              {item.title}
            </Link>
          </li>
        ) : (
          ""
        )
      )}
    </ul>
  </NavigationElem>
);

export default Navigation;
