import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const ToggleElem = styled.div`
  width: 32px;
  height: 25px;
  position: relative;
  top: 5px;
  cursor: pointer;

  > div {
    width: 32px;
    height: 3px;
    background: ${colors.text};
    position: absolute;
    transition: transform 0.2s ease 0s, top 0.3s ease-in-out 0.3s;

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }

  &.open {
    > div {
      transition: top 0.2s ease 0s, transform 0.3s ease-in-out 0.3s;

      &:nth-of-type(1),
      &:nth-of-type(3) {
        top: 11px;
      }

      &:nth-of-type(2) {
        background: rgba(0, 0, 0, 0);
      }

      &:nth-of-type(1) {
        transform: rotate(45deg);
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg);
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;

interface ToggleProps {
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navIsOpen: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ setNavIsOpen, navIsOpen }) => (
  <ToggleElem
    onClick={() => setNavIsOpen(!navIsOpen)}
    className={navIsOpen ? "open" : ""}
  >
    <div />
    <div />
    <div />
  </ToggleElem>
);

export default Toggle;
