import { colors } from "../../styles/variables";

// 1: Verein
// 2: Betreutes Wohnen Heiligkreuz
// 3: Bruggen
// 4: Heiligkreuz

export const getColor = (siteId) => {
  if (siteId === 1) {
    return colors.red;
  }
  if (siteId === 2) {
    return colors.green;
  }
  if (siteId === 3) {
    return colors.orange;
  }
  if (siteId === 4) {
    return colors.blue;
  }
};
