import React, { useState } from "react";
import Helmet from "react-helmet";

import Header from "../components/Header/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer/Footer";
import { getMatomo } from "../components/helpers/getMatomo";

import "normalize.css";
import "../styles/fonts.css";
import "../styles/base.css";

import { graphql, useStaticQuery } from "gatsby";
import { getFaviconFolder } from "../components/helpers/getFaviconFolder";

interface DefaultLayoutProps {
  siteId: number;
  description: string;
  title: string;

  data: {
    cms: {
      meta: {
        siteId: number;
        title: string;
        metaBeschreibung: string;
        adresseLine1: string;
        plzUndOrt: string;
        telefon: string;
        email: string;
        latitude: number;
        longitude: number;
      };
      lowerFooter: [
        {
          siteId: number;
          title: string;
        }
      ];
      navigation: [
        {
          title: string;
          siteId: number;
          uri: string;
        }
      ];
    };
  };
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  siteId,
  description,
  title,
}) => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        meta: entries(type: "data", siteId: [1, 2, 3, 4]) {
          siteId
          title
          ... on CMS_data_data_Entry {
            metaBeschreibung
            adresseLine1
            plzUndOrt
            telefon
            email
            latitude
            longitude
            kartenLink
          }
        }

        lowerFooter: entries(type: "data", siteId: [1, 2, 3, 4]) {
          siteId
          title
        }

        navigation: entries(section: "main", siteId: [1, 2, 3, 4], level: 1) {
          title
          siteId
          uri
        }
      }
    }
  `);

  const [navIsOpen, setNavIsOpen] = useState(false);
  const meta = data.cms.meta.filter((entry) => entry.siteId == siteId);
  return (
    <LayoutRoot navIsOpen={navIsOpen}>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={
            "/favicon/" + getFaviconFolder(siteId) + "/apple-touch-icon.png"
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={"/favicon/" + getFaviconFolder(siteId) + "/favicon-32x32.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={"/favicon/" + getFaviconFolder(siteId) + "/favicon-16x16.png"}
        />
        <link
          rel="manifest"
          href={"/favicon/" + getFaviconFolder(siteId) + "/site.webmanifest"}
        />
        <link
          rel="mask-icon"
          href={
            "/favicon/" + getFaviconFolder(siteId) + "/safari-pinned-tab.svg"
          }
          color="#5bbad5"
        />
        <link
          rel="shortcut icon"
          href={"/favicon/" + getFaviconFolder(siteId) + "/favicon.ico"}
        />
        <meta name="msapplication-TileColor" content="#ffc40d" />
        <meta
          name="msapplication-config"
          content={
            "/favicon/" + getFaviconFolder(siteId) + "/browserconfig.xml"
          }
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content={description ? description : meta[0].description}
        />
        <title>{title + " – " + meta[0].title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        ></meta>
        <link href="/lightbox/css/lightbox.css" rel="stylesheet" />
        <script src="/lightbox/js/lightbox-plus-jquery.js" />
      </Helmet>
      <Header
        setNavIsOpen={setNavIsOpen}
        navIsOpen={navIsOpen}
        content={data.cms.navigation.filter((entry) => entry.siteId == siteId)}
        phone={meta[0].telefon}
        siteId={siteId}
      />
      <LayoutMain>{children}</LayoutMain>
      <Footer
        data={meta[0]}
        siteId={siteId}
        lowerFooter={data.cms.lowerFooter}
      />
    </LayoutRoot>
  );
};

export default DefaultLayout;
