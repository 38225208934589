export const getMapMarker = (siteId) => {
  if (siteId === 1) {
    return "map-marker-verein.svg";
  }
  if (siteId === 2) {
    return "map-marker-betreutes-wohnen.svg";
  }
  if (siteId === 3) {
    return "map-marker-bruggen.svg";
  }
  if (siteId === 4) {
    return "map-marker-heiligkreuz.svg";
  }
};
